import { useLocation, useNavigate } from "react-router-dom";
import { useRealtimeRecipe } from "./hooks/useRealtimeRecipe";
import Button from "@shared/components/primitives/Button";
import Icon from "@shared/components/primitives/Icon";
import { useState, useRef, useEffect } from "react";
import { useImmer } from "use-immer";
import RecipeStepCard from "./components/RecipeStepCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import "swiper/css";
import RecipeOverviewCard from "./components/RecipeOverviewCard";
import Loader from "@shared/components/primitives/Loader";
import { TextFieldInput } from "@radix-ui/themes";
import Modal from "@shared/components/primitives/Modal";

const Recipe = () => {
  const [selectedStepIndex, setSelectedStepIndex] = useState(0);
  const [timerStartTimestamps, setTimerStartTimestamps] = useImmer<Record<string, number | null>>({});
  const [recipeMultiplier, setRecipeMultiplier] = useState<string>("1");

  const location = useLocation();
  const navigate = useNavigate();
  const recipeId = location.pathname.split("/")[2] ?? null;

  const recipe = useRealtimeRecipe(recipeId);

  const handleNextStep = () => {
    if (!recipe || !recipe.recipe_steps) return;
    const nextStepIndex = selectedStepIndex + 1;
    if (nextStepIndex <= recipe.recipe_steps.length) {
      setSelectedStepIndex(nextStepIndex);
    }
  };

  const handlePreviousStep = () => {
    if (!recipe || !recipe.recipe_steps) return;
    const previousStepIndex = selectedStepIndex - 1;
    if (previousStepIndex >= 0) {
      setSelectedStepIndex(previousStepIndex);
    }
  };

  const swiperRef = useRef<SwiperType>();

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(selectedStepIndex);
    }
  }, [selectedStepIndex]);

  const handleSlideChange = (swiper: SwiperType) => {
    setSelectedStepIndex(swiper.activeIndex);
  };

  return (
    <div className="scrollbar-hide flex h-dvh w-screen flex-col overflow-hidden bg-gray-2">
      {!recipe ? (
        <div className="flex min-h-0 flex-grow flex-col items-center justify-center gap-rx-3 p-rx-3">
          <Loader size="xl" className="text-accent-9" />
          <div className="px-rx-7 py-rx-3 text-center text-2 italic text-gray-10">
            Recipe is being prepared, this will only take a few moments
          </div>
        </div>
      ) : (
        <div className="flex min-h-0 flex-grow items-center justify-center px-rx-3 py-rx-2">
          {recipe?.recipe_steps && (
            <Swiper
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              onSlideChange={handleSlideChange}
              spaceBetween={50}
              slidesPerView={1}
              className="h-full w-full overflow-visible"
            >
              <SwiperSlide className="flex h-full w-full items-center justify-center">
                <RecipeOverviewCard recipe={recipe} multiplier={Number(recipeMultiplier)} />
              </SwiperSlide>
              {recipe.recipe_steps.map((step, index) => (
                <SwiperSlide key={index} className="flex h-full w-full items-center justify-center">
                  <RecipeStepCard
                    recipe={recipe}
                    recipeStep={step}
                    timerStartTimestamps={timerStartTimestamps}
                    setTimerStartTimestamps={setTimerStartTimestamps}
                    multiplier={Number(recipeMultiplier)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      )}
      {recipe?.recipe_steps && (
        <div className="h-[4px] w-full">
          <div
            className="h-full bg-accent-9 transition-all duration-300"
            style={{ width: `${(selectedStepIndex / recipe?.recipe_steps?.length) * 100}%` }}
          />
        </div>
      )}
      <div className="flex justify-between border-t border-gray-4 bg-panel px-rx-3 py-rx-2">
        <Button variant="secondary" size="sm" onClick={() => navigate("/")}>
          <Icon icon="home" />
        </Button>
        <Modal.Root>
          <Modal.Trigger asChild>
            <Button variant="ghost" size="sm">
              <Icon icon="times" />
              <span>{Number(recipeMultiplier).toFixed(1)}</span>
            </Button>
          </Modal.Trigger>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Recipe Multiplier</Modal.Title>
              <Modal.HeaderClose />
            </Modal.Header>
            <Modal.Main className="flex w-full justify-end gap-rx-3 !p-rx-4">
              <Modal.Description>
                Adjust the recipe multiplier to make the recipe bigger or smaller. This will affect the amount of ingredients.
              </Modal.Description>
              <div className="w-[100px]">
                <TextFieldInput
                  type="number"
                  placeholder="Multiplier"
                  value={recipeMultiplier}
                  onChange={(e) => setRecipeMultiplier(e.target.value)}
                />
              </div>
            </Modal.Main>
          </Modal.Content>
        </Modal.Root>
        <div className="flex gap-rx-2">
          <Button size="sm" onClick={handlePreviousStep} disabled={selectedStepIndex === 0}>
            <Icon icon="chevron-left" />
            <span>Back</span>
          </Button>
          <Button
            size="sm"
            onClick={handleNextStep}
            disabled={recipe?.recipe_steps ? selectedStepIndex >= recipe?.recipe_steps?.length : true}
          >
            <span>Next</span>
            <Icon icon="chevron-right" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Recipe;
